<template>
  <div>
    <helloWorld />
    <div class="content-1180">
      <div>
        <p class="sort">交易记录</p>
        <div class="rank-select-wrap" style="text-align:center;margin-bottom:20px">
          <el-select size="mini" v-model="value" placeholder="日期">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select size="mini" v-model="value1" placeholder="价格" style="margin-left:10px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select size="mini" v-model="value2" placeholder="交易金额" style="margin-left:10px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table
              :data="transactionList"
              style="width: 100%">
              <el-table-column
                label="商品"
                width="220">
                <template slot-scope="scope">
                  <div class="transacte-first">
                  <img :src="scope.row.assets" alt="">
                  <div style="width:auto !important;text-align:left">
                    <p class="transacte-title">{{scope.row.author}}</p>
                    <p class="transacte-id">{{scope.row.title}}</p>
                  </div>
                </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="unitPrice"
                label="单价">
              </el-table-column>
              <el-table-column
                prop="num"
                label="数量">
              </el-table-column>
              <el-table-column
                prop="saleName"
                label="从">
              </el-table-column>
              <el-table-column
                prop="buyerName"
                label="到">
              </el-table-column>
              <el-table-column
                prop="created"
                width="180px"
                label="日期">
              </el-table-column>
            </el-table>
      <!-- <div class="transacte-head">
        <div>商品</div>
        <div style="text-indent:20px">单价</div>
        <div style="text-indent:10px">交易金额</div>
        <div>从</div>
        <div>到</div>
        <div class="phone-hide">时间</div>
      </div>
      <div>
        <div class="transacte-body" v-for="(item, index) in transactionList" :key="index+'j'" @click="toDetail">
          <div class="transacte-first">
            <img :src="item.assets" alt="">
            <div style="width:auto !important;text-align:left">
              <p class="transacte-title">{{item.author}}</p>
              <p class="transacte-id">{{item.title}}</p>
            </div>
          </div>
          <div style="font-size:12px;color:#333">{{item.unitPrice}}</div>
          <div style="font-size:12px;color:#333">{{item.amount}}</div>
          <div class="blue">{{item.saleName}}</div>
          <div class="blue">{{item.buyerName}}</div>
          <div class="blue phone-hide">{{item.created}}</div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  data() {
    return {
      transactionList: [
        {
          unitPrice: '9.25',
          amount: '9.00',
          saleImg: require(`@/assets/saleImg1.jpg`),
          saleName: '浅水鱼',
          buyerImg: require(`@/assets/saleImg2.jpg`),
          buyerName: '戴小戴',
          created: '2022-02-18 12:28:13',
          author: '亚联融汇科技有限公司',
          title: 'Ape # 8928',
          assets: require(`@/assets/free1.jpg`),
        },
        {
          unitPrice: '25.00',
          amount: '18.8',
          saleImg: require(`@/assets/saleImg3.jpg`),
          saleName: 'Summer',
          buyerImg: require(`@/assets/saleImg4.jpg`),
          buyerName: '不美不媚',
          created: '2022-05-20 20:14:56',
          author: '亚联融汇科技有限公司',
          title: 'ARTSYCOLLECTOR #9160',
          assets: require(`@/assets/free2.jpg`),
        },
        {
          unitPrice: '12.80',
          amount: '12.00',
          saleImg: require(`@/assets/saleImg5.jpg`),
          saleName: '彩虹糖',
          buyerImg: require(`@/assets/saleImg1.jpg`),
          buyerName: '浅水鱼',
          created: '2022-05-28 07:35:10',
          author: '亚联融汇科技有限公司',
          title: 'Bath series',
          assets: require(`@/assets/free3.jpg`),
        },
        {
          unitPrice: '35.00',
          amount: '42.73',
          saleImg: require(`@/assets/saleImg2.jpg`),
          saleName: '戴小戴',
          buyerImg: require(`@/assets/saleImg4.jpg`),
          buyerName: '不美不媚',
          created: '2022-08-26 22:41:05',
          author: '亚联融汇科技有限公司',
          title: 'Bath series2',
          assets: require(`@/assets/free4.jpg`),
        }
      ], //交易记录
      options: [],
        value: '',
        value1: '',
        value2: ''
    }
  },
  methods: {
    toDetail() {
      // this.$router.push('./detail')
    }
  }
}
</script>
<style scoped>
.content-1180 {
  max-width: 1180px;
  margin: auto;
}
.sort {
  font-weight: 600;
    font-size: 24px;
    letter-spacing: 0px;
    color: rgb(4, 17, 29);
    text-align: center;
    padding: 30px 0 20px 0;
}
.transacte-head {
  display: flex;
  align-items: center;
  padding: 10px 0 15px 0;
    margin-top: 20px;
    border-bottom: 1px solid #eee;
}
.transacte-head div {
  width: 16%;
  text-align: center;
  font-weight: 600;
    font-size: 16px;
    color: rgb(4, 17, 29);
}
.transacte-body {
  display: flex;
  align-items: center;
  padding: 10px 0;
    border-bottom: 1px solid #eee;
    text-align: center;
    cursor: pointer;
}
.transacte-body:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;
}
.transacte-body div{
  width: 16%;
}
.transacte-first {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
}
.transacte-first img {
  width: 40px;
  border-radius: 50%;
  margin-right: 4px;
  height: 40px;
}
.transacte-title {
  font-weight: 500;
    font-size: 12px;
    color: rgb(112, 122, 131);
}
.transacte-id {
  font-weight: 600;
    font-size: 12px;
    color: rgb(4, 17, 29);
    margin-top: 2px;
}
.blue {
  color: rgb(24, 104, 183);
  font-size: 12px;
}
</style>